<template>
  <div class="main-view">
    <el-radio-group v-model="table.params.status" @change="handleTabs" style="margin-bottom:10px;">
      <el-radio-button :label="0">全部({{table.total_1}})</el-radio-button>
      <el-radio-button :label="1">招工中({{table.total_2}})</el-radio-button>
      <el-radio-button :label="2">已结束({{table.total_3}})</el-radio-button>
    </el-radio-group>
    <div class="section shadow filter__wrap">
      <el-form ref="elFormDom" inline :model="table.params" size="mini">
        <el-form-item label="标题搜索:" prop="keyword">
          <el-input clearable v-model.trim="table.params.keyword" placeholder="请输入标题"></el-input>
        </el-form-item>
        <el-form-item label="工种类型:" prop="kinds">
          <el-select v-model="table.params.kinds" clearable placeholder="请选择工种类型">
            <el-option
              v-for="item in setKinds"
              :key="item.id"
              :label="item.name"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="发布账号:" prop="account">
          <el-input clearable v-model.trim="table.params.account" placeholder="请输入发布账号"></el-input>
        </el-form-item>
        <!-- 市级账号才有所属区域 -->
        <el-form-item label="所属区域" prop="area_arr" v-if="cur_area == 0">
          <el-cascader
            :popper-append-to-body="false"
            popper-class="areaCascaderWrap"
            v-model="table.params.area_arr"
            :options="areaArr"
            :props="{ checkStrictly: true }">
          </el-cascader>
        </el-form-item>
        <el-form-item>
          <el-button class="theneBtn" type="primary" icon="el-icon-search" @click="onSearch">查询 </el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <!-- <el-button v-if="table.params.status !== 2" size="mini" type="primary" plain @click="handleExportBatch">批量结束</el-button> -->
    </div>
    
    <VTable
      has-pagionation
      title=''
      addText='添加'
      :field="cur_area != 0 ? field : field2"
      :loading="table.loading"
      :data="table.data"
      :page='table.params.page'
      :pageSize='table.params.count'
      :total='table.total'
      :hasPagionation="true"
      :default-expand-all="true"
      :showOverflowTooltip="true"
      :showSelect="false"
      @handleSizeChange="handleSizeChange"
      @handleCurrentChange="handleCurrentChange"
      @handleSelectionChange="handleSelectionChange"
    >
      <template v-slot:amount="{row}">
        <span v-if="row.use_num > 1">￥{{row.amount}}/人</span>
        <span v-else>￥{{row.amount}}</span>
      </template>
      <template v-slot:province_str="{row}">
        <span >{{row.area_str}}</span>
      </template>
      <template v-slot:content="{row}">
        <span class="popSpan">{{row.content}}</span>
      </template>
      <template v-slot:price_type="{row}">
        <!-- 判断是否有企业id，有企业id则是驻店员发的，定价方式就是“小时工” -->
        <span v-if="!!row.company_id">小时工</span>
        <span v-else>{{toStr({1:'自主定价',2:'对方报价',3:'平台专享价'}, row.price_type)}}</span>
      </template>
      <template v-slot:status="{row}">
        <span :style="{ color: statusOpt[row.status].color }">{{statusOpt[row.status].name}}</span>
      </template>
      <template v-slot:action="{row}">
        <!-- <el-button type="text" v-if="row.status === 1" @click="showEdit(row, 1)">结束招工</el-button> -->
        <el-button type="text" icon="el-icon-view" @click="showDetail(row)">查看</el-button>
      </template>
    </VTable>

    <edit ref="edit" @refresh="getTable"></edit>
    <detail ref="detail"></detail>
  </div>
</template>

<script>
import VTable from '@/components/VTable';
import Edit from './components/Edit.vue';
import Detail from './components/Detail.vue';
import { mixinTable } from '@/mixins/table.js'
import { mapGetters } from 'vuex'
import { getStorage } from '@/storage/index.js'
export default {
  name: 'RecruitList',
  components: {
    VTable,
    Edit,
    Detail,
  },
  mixins: [mixinTable],
  computed: {
    ...mapGetters(['setKinds'])
  },
  data() {
    return {
      field: [
        { name: "parent_kinds_str", label: "工种类型", width:'150', showTooltip: true, hidden: false },
        { name: "kinds_str", label: "招工标题", width: "200", showTooltip: true, hidden: false },
        { name: "content", label: "工作描述", width: "200", hidden: false },
        { name: "name", label: "发布人", showTooltip:true, hidden: false },
        { name: "account", label: "发布账号", width: "120", hidden: false },
        { name: "create_time", label: "发布时间", width: "150", hidden: false },
        { name: "amount", label: "金额", hidden: false },
        { name: "price_type", label: "定价方式", width:"100", hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "120", hidden: false }
      ],
      field2: [
        { name: "parent_kinds_str", label: "工种类型", width:'150', showTooltip: true, hidden: false },
        { name: "kinds_str", label: "招工标题", width: "200", showTooltip: true, hidden: false },
        { name: "province_str", label: "所在区域", width: "200", hidden: false },
        { name: "content", label: "工作描述", width: "200", hidden: false },
        { name: "name", label: "发布人", showTooltip:true, hidden: false },
        { name: "account", label: "发布账号", width: "120", hidden: false },
        { name: "create_time", label: "发布时间", width: "150", hidden: false },
        { name: "amount", label: "金额", hidden: false },
        { name: "price_type", label: "定价方式", width:"100", hidden: false },
        { name: "status", label: "状态", hidden: false },
        { name: "action", label: "操作", fixed:"right", width: "120", hidden: false }
      ],
      table: {
        loading: false,
        total_1: '',
        total_2: '',
        total_3: '',
        params: {
          status: 0,
          keyword: '',
          kinds: '',
          account: '',
          province: '',
          city: '',
          area: '',
          area_arr: [],
          page: 1,
          count: 10
        },
        data: [],
        total: 0,
      },
      settleType: {
        1:'日结',
        2:'项目结',
        3:'可议'
      },
      statusOpt: {
        1:{name:'招工中',color:'#67c23a'},
        2:{name:'已结束',color:'#f56c6c'}
      },
      valBatch: [],
      areaArr: [],
      cur_area: getStorage("area"), // 区域0是县级账号，不为0者市级账号
    }
  },
  created() {
    this.getTable();
    this.getArea();
  },
  methods: {
    getTable() {
      let _area_arr = this.table.params.area_arr;
      this.table.params.province = _area_arr[0];
      this.table.params.city = _area_arr[1];
      this.table.params.area = _area_arr[2];
      let _params = { ...this.table.params }
      delete _params.area_arr;
      this.$http.get('/government/job/list', { params:_params }).then(res => {
        if(res.code === 1) {
          this.table.data = res.data.list;
          this.table.total = res.data.total;
          this.table.total_1 = res.data.total_1;
          this.table.total_2 = res.data.total_2;
          this.table.total_3 = res.data.total_3;
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    // 获取省市区
    getArea() {
      this.$http.post('/common/district/all',{}).then(res => {
        if(res.code == 1) {
          this.areaArr = res.data.list;
        }
      })
    },
    showEdit(data, type) {
      let dom = this.$refs.edit
      dom.toggle(true)
      dom.getDetail(data, type)
      dom = null
    },
    // 查看
    showDetail(row) {
      let dom = this.$refs.detail
      dom.toggle(true)
      dom.getDetail(row)
      dom = null
    },
    // 结束招工
    remove(row) {
      this.$confirm("请确认是否结束招工？", "提示", {
        beforeClose: (action, instance, done) => {
          if (action === "confirm") {
            this.delete(row.id);
            done()
          } else {
            done()
          }
        }
      }).catch(() => {})
    },
    delete(rowid) {
      this.$http.post('/government/job/operation', { id: rowid }).then(res => {
        if(res.code === 1) {
          this.$message.success('操作成功！')
          this.getTable();
        } else {
          this.$message.error(res.data)
        }
      })
    },
    handleSelectionChange(valBatch) {
      this.valBatch = valBatch;
    },
    handleExportBatch() {
      if(this.valBatch.length > 0) {
        this.showEdit(this.valBatch, 2)
      } else {
        this.$message.warning('请选择需批量操作的数据')
      }
    }
  }
}
</script>

<style scoped>
.popSpan {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

</style>
<style>
.areaCascaderWrap .el-cascader-menu:nth-of-type(1) .el-radio {
  display: none !important;
}
</style>

