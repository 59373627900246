<template>
  <div div class="addPerson__wrap" v-show="visible">
    <el-button class="backBtn" plain icon="el-icon-d-arrow-left" @click="toggle(false)">返回</el-button>
    <div class="main">
      <p style="text-align:right;" v-if="dataInfo.info.cancel_type > 0">
        <el-tag>结束类型：{{dataInfo.info.cancel_type === 1 ? '用户结束' : '后台结束'}}</el-tag>
      </p>
      <el-divider content-position="left">个人信息</el-divider>
      <div class="info-top">
        <div style="text-align:left;">
          <p>发布者：{{dataInfo.info.name}}</p>
          <p>联系电话：{{dataInfo.info.account}}</p>
          <p v-if="!!dataInfo.info.company_name">所属企业：{{dataInfo.info.company_name}}</p>
        </div>
        <el-avatar
          style="width: 80px; height: 80px;margin-left:50px"
          class="avatar_img"
          shape="square"
          :src="dataInfo.info.avatar"
          fit="cover">
          <img :src="require('@/assets/img/default.png')"/>
        </el-avatar>
      </div>
      <el-divider></el-divider>
      <el-descriptions title="招工信息" :column="1" border>
        <el-descriptions-item label="发布时间" :labelStyle='labelStyle'>{{dataInfo.info.create_time}}</el-descriptions-item>
        <el-descriptions-item label="所属工种" :labelStyle='labelStyle'>{{dataInfo.info.parent_kinds_str}}</el-descriptions-item>
        <el-descriptions-item label="招工标题" :labelStyle='labelStyle'>{{dataInfo.info.kinds_str}}</el-descriptions-item>
        <!-- 若有规格则展示规格 -->
        <el-descriptions-item v-if="!!dataInfo.info.item_str" label="规格" :labelStyle='labelStyle'>{{dataInfo.info.item_str}} > {{dataInfo.info.spec_str}}</el-descriptions-item>
        <!-- 若有用工人数则展示人数 -->
        <el-descriptions-item v-if="dataInfo.info.use_num > 1" label="用工人数" :labelStyle='labelStyle'>{{dataInfo.info.use_num}}人</el-descriptions-item>
        <!-- kinds:190 陪诊服务 -->
        <template v-if="dataInfo.info.kinds === 190">
          <el-descriptions-item label="陪诊医院" :labelStyle='labelStyle'>{{dataInfo.info.pz_hospital}}</el-descriptions-item>
          <el-descriptions-item label="科室" :labelStyle='labelStyle'>{{dataInfo.info.pz_dep}}</el-descriptions-item>
          <el-descriptions-item label="就诊时间" :labelStyle='labelStyle'>{{dataInfo.info.pz_time}}</el-descriptions-item>
          <el-descriptions-item label="就 诊 人" :labelStyle='labelStyle'>{{dataInfo.info.pz_serve_name}}&nbsp;&nbsp;{{dataInfo.info.pz_serve_mobile}}&nbsp;&nbsp;{{dataInfo.info.pz_serve_relation_str}}</el-descriptions-item>
          <el-descriptions-item label="身份证号" :labelStyle='labelStyle'>{{dataInfo.info.pz_serve_id_card}}</el-descriptions-item>
          <el-descriptions-item label="需要陪诊时间" :labelStyle='labelStyle'>{{dataInfo.info.pz_duration == 1 ? '半天' : '一天'}}</el-descriptions-item>
          <el-descriptions-item label="是否需要接送" :labelStyle='labelStyle'>{{dataInfo.info.pz_serve == 1 ? '是' : '否'}}</el-descriptions-item>
          <el-descriptions-item label="服务地址" :labelStyle='labelStyle'>{{dataInfo.info.address}}</el-descriptions-item>
        </template>
        <template v-else>
          <!-- 做饭阿姨用工时间 food_time -->
          <template v-if="dataInfo.info.kinds === 183">
            <el-descriptions-item label="用工时间" :labelStyle='labelStyle'>{{dataInfo.info.food_time}}</el-descriptions-item>
            <el-descriptions-item label="做菜数量" :labelStyle='labelStyle'>{{dataInfo.info.food_num}} 个</el-descriptions-item>
            <el-descriptions-item label="菜系口味" :labelStyle='labelStyle'>{{dataInfo.info.food_flavor}}</el-descriptions-item>
            <el-descriptions-item label="是否需要买菜" :labelStyle='labelStyle'>{{dataInfo.info.food_buy == 1 ? '是' : '否'}}</el-descriptions-item>
          </template>
          <el-descriptions-item v-else label="用工时间" :labelStyle='labelStyle'>{{dataInfo.info.start_time}} - {{dataInfo.info.end_time}}</el-descriptions-item>
          
          <el-descriptions-item v-if="dataInfo.info.kinds === 191" label="医院地址" :labelStyle='labelStyle'>{{dataInfo.info.address}}</el-descriptions-item>
          <el-descriptions-item v-else-if="dataInfo.info.kinds === 192" label="购买地址" :labelStyle='labelStyle'>{{dataInfo.info.address}}</el-descriptions-item>
          <el-descriptions-item v-else label="用工地址" :labelStyle='labelStyle'>{{dataInfo.info.address}}</el-descriptions-item>
          <el-descriptions-item label="联系方式" :labelStyle='labelStyle'>{{dataInfo.info.contact_name}}&nbsp;&nbsp;{{dataInfo.info.contact_phone}}</el-descriptions-item>
        </template>
        <el-descriptions-item label="需求详情" :labelStyle='labelStyle'>{{dataInfo.info.content}}</el-descriptions-item>
        <el-descriptions-item label="工单金额" :labelStyle='labelStyle'>
          <template v-if="dataInfo.info.price_type == 1 || dataInfo.info.price_type == 3">
            <span v-if="dataInfo.info.use_num > 1">￥{{dataInfo.info.amount}}/人&nbsp;&nbsp;</span>
            <span v-else>￥{{dataInfo.info.amount}}&nbsp;&nbsp;</span>
            <span v-if="!!dataInfo.info.company_id" style="font-size:10px;color:#1966FF">小时工</span>
            <span v-else style="font-size:10px;color:#1966FF">{{toStr({1:'自主定价',2:'对方报价',3:'平台专享价'},dataInfo.info.price_type)}}</span>
          </template>
          <!-- 如果用户选择的是对方报价，这里展示：待报价 -->
          <template v-else>
            <span>待报价&nbsp;&nbsp;</span>
            <span v-if="!!dataInfo.info.company_id" style="font-size:10px;color:#1966FF">小时工</span>
            <span v-else style="font-size:10px;color:#1966FF">{{toStr({1:'自主定价',2:'对方报价',3:'平台专享价'},dataInfo.info.price_type)}}</span>
          </template>
        </el-descriptions-item>
        <el-descriptions-item label="支付状态" v-if="dataInfo.info.price_type == 3">{{toStr({0:'未支付',1:'已支付',2:'支付中'},dataInfo.info.is_pay)}}</el-descriptions-item>
        <!-- <el-descriptions-item label="结算金额" :labelStyle='labelStyle'>￥{{dataInfo.info.amount}} &nbsp;&nbsp;{{settleType[dataInfo.info.settle_type]}}</el-descriptions-item> -->
        <el-descriptions-item label="浏览量" :labelStyle='labelStyle'>{{dataInfo.info.browse_count}}次</el-descriptions-item>
        <el-descriptions-item label="报名人数" :labelStyle='labelStyle'>{{dataInfo.info.apply_num}}人</el-descriptions-item>
        <template v-if="dataInfo.info.cancel_type > 0">
          <el-descriptions-item label="結束时间" :labelStyle='labelStyle'>{{dataInfo.info.cancel_time}}</el-descriptions-item>
          <el-descriptions-item label="结束原因" :labelStyle='labelStyle'>{{dataInfo.info.reason}}</el-descriptions-item>
        </template>
      </el-descriptions>
      <div style="display:flex;">
        <div class="block portrait" v-for="(item, i) in dataInfo.apply" :key="i">
          <div style="display:flex;align-items:center;" >
            <el-avatar size="large" :src="item.avatar"></el-avatar>
            <div style="font-size:12px;margin-left:10px;">
              <el-rate v-model="item.score" disabled text-color="#ff9900" v-if="!!item.score" ></el-rate>
              <div v-else>暂无评分</div>
              <div style="text-align:left;">服务次数{{item.service_num}}</div>
            </div>
          </div>
          <div style="margin-top:5px;">{{item.name}}</div>
          <div>{{item.phone}}</div>
          <!-- 定价方式：对方报价，则展示报价 -->
          <div v-if="dataInfo.info.price_type == 2" style="color: #f56c6c;font-weight: bold;margin-top: 5px;">报价：{{item.amount}}元</div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'RecruitDetail',
  data() {
    return {
      visible: false,
      dataInfo: {
        info: {},
        apply: [],
      },
      status: 1,
      settleType: {
        1:'日结',
        2:'项目结',
        3:'可议'
      },
      labelStyle: { 'width': '160px' },
    }
  },
  methods: {
    // 获取详情
    getDetail(row) {
      this.isChange = true
      this.$http.get('/government/job/info', {params:{id: row.id} }).then(res => {
        if(res.code === 1) {
          this.dataInfo = res.data
        } else {
          this.$message.error(res.msg)
        }
      })
    },
    reset(done) {
      this.isChange = false
      // this.$refs.elFormDom.resetFields()
      done && done()
    },
    toggle(show) {
      this.visible = show
      !show && this.reset()
    },
    toStr(data, key) {
      return data[key]
    }
  }
}
</script>
<style scoped lang="scss">
.addPerson__wrap {
  @include position($t: 0, $r: 0, $b: 0, $l: 0);
  background-color: #fff;
  margin: 10px;
  z-index: 5 !important;
  overflow: scroll;

  .main {
    width: 80%;
    margin: 0 auto;
    text-align: center;
  }
}
.info-top{
  display: flex;
  justify-content: start;
  align-items: center;
}
.portrait {
  text-align: left;
  margin-right: 20px;
  margin-top: 10px;
  color: #606266;
}
.portrait ::v-deep .el-avatar--large {
  width: 60px;
  height: 60px;
  line-height: 40px;
}
.avatar_img ::v-deep img {
  width: 100%;
  height: 100%;
}
</style>