<template>
  <el-dialog
    append-to-body
    width="600px"
    title="结束招工"
    :visible.sync="visible"
    :before-close="reset"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <el-form
      v-loading="loading"
      element-loading-text="加载中…"
      ref='elFormDom'
      label-width='110px'
      label-position='right'
      :model='form.data'
      :rules='form.rules'
    >
      <el-row :gutter="10">
        <el-col :span="24">
          <el-form-item prop='reason' label='结束原因：'>
            <el-select v-model="form.data.reason" placeholder="请选择结束原因">
              <el-option
                v-for="item in typeOpt"
                :key="item.id"
                :label="item.name"
                :value="item.id">
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="24">
           <el-form-item prop='mark' label='发送通知：'>
            <el-input
              type="textarea"
              :autosize="{ minRows: 2, maxRows: 8}"
              v-model="form.data.mark"
              maxlength="200"
              show-word-limit
              :readonly="true">
            </el-input>
           </el-form-item>
        </el-col>
      </el-row> 
    </el-form>
    <template #footer>
      <el-row type="flex" justify="end">
        <el-button @click='toggle(false)'>取消</el-button>
        <el-button type="primary" :loading="form.loading" :disabled="form.loading" @click='confirm'>确定</el-button>
      </el-row>
    </template>
  </el-dialog>
</template>

<script>
  export default {
    name: 'RecruitEdit',
    data() {
      return {
        visible: false,
        isChange: false,
        loading: false,
        form: {
          loading: false,
          data: {
            ids: '',
            reason: '',
            mark: '',
          },
          rules: {
            reason: [{ required: true, message:'请选择结束原因', trigger: 'blur'}],
          }
        },
        typeOpt: [
          {
            id: 6,
            name: '内容违规'
          },{
            id: 7,
            name: '虚假信息'
          }
        ],
      }
    },
    methods: {
      // 获取企业详情
      getDetail(data, type) {
        this.isChange = true
        if(type === 1) {
          this.form.data.ids = data.id;
          this.form.data.mark = `尊敬的用户您好，您发布的招工【${data.title}】经系统审查与平台功能用途不符，系统已关闭您的招工，如仍需发布请您修改后再提交，感谢您的配合。`
        } else if (type === 2) {
          let curIds = []
          let str = []
          data.forEach(v => {
            curIds.push(v.id)
            str.push(v.title)
          });
         this.form.data.ids = curIds.join('|')
         this.form.data.mark = `尊敬的用户您好，您发布的招工【${str.join(" 、 ")}】经系统审查与平台功能用途不符，系统已关闭您的招工，如仍需发布请您修改后再提交，感谢您的配合。`
        }
      },
      reset(done) {
        this.isChange = false
        this.$refs.elFormDom.resetFields()
        done && done()
      },
      toggle(show) {
        this.visible = show
        !show && this.reset()
      },
      // 提交
      confirm() {
        this.$refs.elFormDom.validate(valid => {
          if (valid) {
           this.form.loading = true;
            this.$http.post('/government/flex_job/operation', this.form.data).then(res => {
              if(res.code === 1) {
                this.toggle(false);
                this.$emit('refresh')
                this.$message.success('操作成功！')
              } else {
                this.$message.error(res.msg)
              }
            }).finally(() => {
              this.form.loading = false;
            })
          }
        })
      }
    }
  }
</script>
